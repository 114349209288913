.page-enter {
    opacity: 0;
  }
  
  .page-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  
  .page-exit {
    opacity: 1;
  }
  
  .page-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
 

  .page-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  .page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .page-exit {
    opacity: 1;
    transform: translateX(0);
  }
  .page-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
  